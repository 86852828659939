/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MidasEffectError, User } from '@midas/shared/common';
import { createAction, props } from '@ngrx/store';

export const getProfile = createAction('[User] Get Profile');
export const getProfileSuccess = createAction('[User] Get Profile Success', props<{ user: User }>());
export const getProfileError = createAction('[User] Get Profile Error', props<MidasEffectError>());

export const updateProfile = createAction(
  '[User] Update Profile',
  props<{
    username: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    cellNumber: string | null;
  }>()
);
export const updateProfileSuccess = createAction(
  '[User] Update Profile Success',
  props<{
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    cellNumber: string | null;
  }>()
);
export const updateProfileError = createAction('[User] Update Profile Error', props<MidasEffectError>());

export const refreshProfile = createAction('[User] Refresh Profile');

export const setUserSmsMfa = createAction(
  '[User] Set User Sms Mfa',
  props<{ username: string; password: string; phoneNumber: string }>()
);
export const setUserSmsMfaSuccess = createAction('[User] Set User Sms Mfa Success');
export const setUserSmsMfaError = createAction('[User] Set User Sms Mfa Error', props<MidasEffectError>());

export const verifyUserSmsMfa = createAction('[User] Verify User Sms Mfa', props<{ code: string }>());
export const verifyUserSmsMfaSuccess = createAction(
  '[User] Verify User Sms Mfa Success',
  props<{ user: User }>()
);
export const verifyUserSmsMfaError = createAction(
  '[User] Verify User Sms Mfa Error',
  props<MidasEffectError>()
);

export const disableUserMfa = createAction('[User] Disable User Mfa', props<{ currentPassword: string }>());
export const disableUserMfaSuccess = createAction(
  '[User] Disable User Mfa Success',
  props<{ action: string; session: string }>()
);
export const disableUserMfaError = createAction('[User] Disable User Mfa Error', props<MidasEffectError>());

export const verifyDisableUserMfa = createAction(
  '[User] Verify Disable User Mfa',
  props<{ code: string; session: string }>()
);
export const verifyDisableUserMfaSuccess = createAction(
  '[User] Verify Disable User Mfa Success',
  props<{ user: User }>()
);
export const verifyDisableUserMfaError = createAction(
  '[User] Verify Disable User Mfa Error',
  props<MidasEffectError>()
);

/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Cart } from './cart.model';
import { Sample } from './transport.model';

export interface CollectionBin {
  id: number;
  serialNumber: string;
  collectionBinType: CollectionBinType;
  containerTypeName: string;
  capacity: number;
  wastedCount: number;
  isActive: boolean;
  percentFull: number;
}

export interface CollectionBox {
  containerTypeName: string;
  capacity: number;
  wastedCount: number;
  samples: Sample[];
  percentFull: number;
}

export enum CollectionBinType {
  NonHazardous,
  Hazardous,
}

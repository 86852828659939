/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { BinSwap } from "./bin-swap.model";
import { Transport } from "./transport.model";
import { Wasting, WastingType, WorkFlowStatus } from "./wasting.model";

export enum WorkflowType
{
    Transport,
    CentralPharmacy
}

export enum VideoState
{
    Good = 0,
    IncompleteVideo = 1,
    MissingVideo = 2,
    NoVideo = 3
}

export enum InterruptedState
{
    None = 0,
    Back = 1,
    Home = 2,
    Logout = 3,
    AutoLogout = 4,
    NetworkDisconnect = 5,
    CameraError = 6,
    Skipped = 7,
    BagTamperedWith = 8,
    CountMismatch = 9
}

export interface IWorkflow {
  id: number;
  workflowStatus: WorkFlowStatus;
  videoUploadComplete: boolean;
  videoFilePath: string | null;
  videoURL: string | null;
  localFilePath: string;
  videoCheckSum: string | null;
  is360Video: boolean;
  showWitnessVideo: boolean;
  interruptedState: InterruptedState;
  videoState: VideoState;
}

export interface VideoUrlsResponse {
    videoReadUrl: string;
    videoDownloadUrl: string;
    videoCheckSumFail: boolean;
    witnessVideoReadUrl: string;
    witnessVideoDownloadUrl: string;
    screenVideoReadUrl: string;
    screenVideoDownloadUrl: string;
    wastingThumbnailReadUrl: string;
  }

export function isTransport(item: any): item is Transport {
    return 'transportIdentifier' in item;
}

export function isWasting(item: any): item is Wasting {
    return 'wasteUser' in item;
}

export function isBinSwap(item: any): item is BinSwap {
    return 'binSwapUser' in item;
}

export function isStandardWaste(item: any): item is Wasting {
    return item.wastingType === WastingType.Standard;
}

export function isVideoUploading(item: IWorkflow) {
    return !item.videoUploadComplete && (item.videoState === VideoState.Good || item.videoState === VideoState.IncompleteVideo);
}

export function canViewDetails(item: IWorkflow) {
    return item.videoUploadComplete || item.videoState === VideoState.MissingVideo || item.videoState === VideoState.NoVideo;
}
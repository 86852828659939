/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { isVideoUploading, IWorkflow, VideoState } from '@midas/shared/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'midas-video-thumbnail',
  template: `
    <ng-container *transloco="let t">
      <div class="video-container" *ngIf="workflow?.videoUploadComplete && src">
        <img [width]="width" [src]="src"/>
      </div>

      <div *ngIf="workflow?.videoUploadComplete && this.isIncomplete"
          [style.display]="'flex'"
          [style.align-items]="'center'"
          [style.justify-content]="'center'">
        <mat-icon
            [style.color]="'var(--perm-false-color)'"
            [style.width.px]="24"
            [style.height.px]="24"
            [style.font-size.px]="24"
            [style.margin-right.px]="5">
          warning-amber
        </mat-icon>
        <span [style.color]="'var(--perm-false-color)'">
          {{ t('Incomplete Video') }}
        </span>
      </div>

      <div class="video-container" *ngIf="this.isVideoUploading">
        <img [width]="width" [height]="height" src="/assets/test_pattern.png" alt="Uploading" />
        <div class="video-centered">{{ t('Uploading...') }}</div>
      </div>

      <div class="video-container" *ngIf="videoCheckSumFail">
        <img [width]="width" [height]="height" src="/assets/static.png" alt="Error" />
        <div class="video-centered">{{ t('Error') }}</div>
      </div>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslocoModule],
})
export class VideoThumbnailComponent implements OnInit {
  @Input() workflow?: IWorkflow;
  @Input() videoCheckSumFail = false;
  @Input() width = 220;
  @Input() height = 140;
  @Input() src = '';

  isVideoUploading = false;
  isIncomplete = false;

  ngOnInit() {
    this.isVideoUploading = this.workflow ? isVideoUploading(this.workflow) : false;
    this.isIncomplete = this.workflow?.videoState === VideoState.IncompleteVideo;
  }
}

/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { SortCriteriaItem } from './base.types';
import { Formulary, FormularyWitnessRequirement } from './formulary.model';
import { ManagedUser } from './managed-user.model';
import { Patient } from './patient.model';
import { IWorkflow } from './workflow.model';
import { TranslocoService } from '@ngneat/transloco';

export enum WastingReason {
  ExpectedWaste = 1,
  Refused = 2,
  OrderChanged = 4,
  Dropped = 8,
  Others = 16,
  Override = 32,
  FoundMedication = 64,
}

export enum WorkFlowStatus {
  Unknown = 0,
  Completed = 1,
  Pending = 2,
  Unresolved = 3,
  Resolved = 4,
  Cancelled = 5,
  Interrupted = 6,
}

export const WorkFlowRequiresAttention = (status: WorkFlowStatus) =>
  status === WorkFlowStatus.Pending || status === WorkFlowStatus.Unresolved;

export enum MedicationForm {
  Tablet = 1,
  Suppository = 2,
  Film = 4,
  Patch = 8,
  Capsule = 16,
  Ampule = 32,
  Vial = 64,
  Syringe = 128,
  Liquid = 256,
  Inhaler = 512,
  Others = 1024,
}

export interface Wasting extends IWorkflow {
  wasteAmount: number;
  wasteUnits: string | null;
  reasonForWaste: WastingReason | null;
  otherComments: string | null;
  otherReasons: string | null;
  wastingVideoFilePath: string;
  wastingVideoDuration: string;
  witnessVideoFilePath: string;
  screenVideoFilePath: string;
  wastingTime: string;
  locationId: number;
  locationName: string;
  cartId: number;
  cartName: string;
  sampleIdentifier: string;

  wasteUser: ManagedUser;
  witnessUser: ManagedUser | null;
  witnessUserId: number | null;

  toBeCollected: boolean;
  workflowStatus: WorkFlowStatus; // replace with WorkflowStatus in IWorkflow eventually
  witnessRequirement: FormularyWitnessRequirement | null;
  witnessType: FormularyWitnessRequirement | null;

  wastingType: WastingType;
  sampleId: number | null;

  // below fields are only used for cancelled or auto-logged out wasting where patient or medication has been selected
  // for non-exception wastings
  selectedPatientID: number | null;
  selectedPatient: Patient | null;
  selectedFormularyID: number | null;
  selectedFormulary: Formulary | null;

  // below fields are only used for Misc Wastes
  medicationFoundLocation: string | null;
  formOfMedicationDescription: string;
  formOfMedication: MedicationForm;
  genericName: string | null;
  tradeName: string | null;
  supplyStrength: number | null;
  supplyStrengthUnit: string | null;
  medicationDescription: string | null;
  isHazardous: boolean;
  isLiquid: boolean;
  sessionId: string | null;
}

export enum WastingSortCriteria {
  Date,
  Location,
  Cart,
  Waster,
  Witness,
  Patient,
  Formulary,
}

export type WastingSortCriteriaItem = SortCriteriaItem<typeof WastingSortCriteria>;

export interface QueryWastingsRequest {
  limit: number;
  offset: number;
  searchString?: string;
  locations?: number[];
  carts?: number[];
  patients?: number[];
  wasters?: number[];
  witnesses?: number[];
  formulary?: number[];
  endDate?: string | null;
  startDate?: string | null;
  sortCriteria?: WastingSortCriteriaItem[];
  statuses: WorkFlowStatus[];
  wastingTypes: WastingType[];
  showUserWastes?: boolean;
  transportId?: number;
}

export enum WastingPageType {
  AnytimeWitness,
  ReviewRequired,
  HistoricalWastes,
  FinalWasteAnytimeWitness,
  FinalWasteHistoricalWastes
}

export enum WastingType {
  Standard,
  Final,
}

export function getDrugLabel(item: Wasting, transloco: TranslocoService) {
      if (item.genericName != null && item.genericName != '') return item.genericName;
      else if (item.tradeName != null && item.tradeName != '') return item.tradeName;
      else return transloco.translate('Manually Entered Medication');
}
/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { SortCriteriaItem } from './base.types';
import { Cart } from './cart.model';
import { Medication } from './formulary.model';
import { Location } from './location.model';
import { ManagedUser } from './managed-user.model';
import { Wasting, WorkFlowStatus } from './wasting.model';
import { InterruptedState, IWorkflow } from './workflow.model';

export interface Sample {
  id: number;
  wasting: Wasting;
  medication?: Medication;
  sampleIdentifier: string;
  isMissing: boolean;
  finalWasting: Wasting | null;
}

export interface Transport extends IWorkflow {
  transportIdentifier: string | null;
  originalTransportIdentifier: string | null;
  originalTransportId: number | null;
  cart: Cart;
  location: Location;
  transportUser: ManagedUser;
  transportWitnessUser?: ManagedUser;
  samples: Sample[];
  transportCollectionTime: string;
  originalTransport?: Transport | null;
  transportUserName: string | null;
  cartName: string | null;
  locationName: string | null;
  transportType: TransportType;
  finalWastingStatus: WorkFlowStatus | null;
  finalInterruptedState: InterruptedState | null;
}

export enum TransportsSortCriteria {
  Date,
  Location,
  Cart,
  User,
  Samples,
}

export type TransportsSortCriteriaItem = SortCriteriaItem<typeof TransportsSortCriteria>;

export interface QueryTransportsRequest {
  limit: number;
  offset: number;
  searchString?: string;
  locations?: number[];
  carts?: number[];
  users?: number[];
  transports?: number[];
  startDate?: string | null;
  endDate?: string | null;
  sortCriteria?: TransportsSortCriteriaItem[];
  statuses: WorkFlowStatus[];
  showUserTransports?: boolean;
}

export interface QueryTransportsPharmacyRequest {
  limit: number;
  offset: number;
}

export enum TransportsPageType {
  AnytimeWitness,
  ReviewRequired,
  HistoricalWastes,
}

export enum TransportType {
  Transport,
  Review
}

export function getUnapprovedWastes(transport: Transport) {
  return transport.samples.filter(
    (s) => s.wasting !== null && (s.wasting.workflowStatus !== WorkFlowStatus.Completed && s.wasting.workflowStatus !== WorkFlowStatus.Resolved),
  ).length;
}

export function getMissingSamples(transport: Transport) {
  return transport.samples.filter(
    (s) => s.isMissing,
  ).length;
}

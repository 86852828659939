/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { ManagedUser } from './managed-user.model';

export enum MidasAdminOrganizationPermissions {
  Readonly = 1,
  ReadAndWrite = 2,
}

export interface Organization {
  id: number;
  name: string;
  midasAdminOrganizationPermissions: MidasAdminOrganizationPermissions;
  createdAt: string;
  updatedAt: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: number | null;
  cartAutoLogoutTime: number;
  anytimeWasteNotificationTimePeriod: number;
  anytimeTransportNotificationTimePeriod: number;
  contactName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
  rootUser: ManagedUser;
  accountNumber: string;
}

/** Copyright 2024 Midas Healthcare Solutions - All Rights Reserved **/
import { SortCriteriaItem } from "./base.types";
import { CollectionBin } from "./collection-bin.model";
import { ManagedUser } from "./managed-user.model";
import { IWorkflow } from "./workflow.model";

export interface BinSwap extends IWorkflow {
  cartId: number;
  cartName: string;
  locationId: number;
  locationName: string;
  binSwapUser: ManagedUser;
  binSwapTime: string;
  removedCollectionBin: CollectionBin;
  installedCollectionBin: CollectionBin;
}

export enum BinSwapSortCriteria {
  Date,
  Location,
  Cart,
  User
}

export type BinSwapSortCriteriaItem = SortCriteriaItem<typeof BinSwapSortCriteria>;

export interface QueryBinSwapsRequest {
  limit: number;
  offset: number;
  locations?: number[];
  carts?: number[];
  users?: number[];
  startDate?: string | null;
  endDate?: string | null;
  sortCriteria?: BinSwapSortCriteriaItem[];
}
